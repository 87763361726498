import './Error.css';
import { State } from './PasswordChange'
import { useContext } from 'react'

const ErrorDisplay = () => {

    const { errorMessage } = useContext(State);

    if (errorMessage && errorMessage.length !== 0){
        return (
            <div className='message'>
                {errorMessage.map((message) => <li key={message}>{message}</li>)}
            </div>
        )
    } else {
        return <></>
    }
};

export default ErrorDisplay;
