import {createContext, useEffect, useMemo, useState} from 'react'
import {initializeApp} from 'firebase/app'
import useMedia from 'use-media';
import PasswordChange from './PasswordChange'
import VerifyEmail from './VerifyEmail';
import Verified from './Verified';

export type MediaType = {isMobileSite: boolean; isPcSite: boolean;};
export const SiteType = createContext<Partial<MediaType>>({});

const App = () => {
    const [mode, setMode] = useState<string>();
    const [initFlag, setInitFlag] = useState<boolean>(false);

    const mediaQueries = {
            mobile: '(max-width: 599px)',
            pc: '(min-width: 600px)',
    };
    const isMobileSite = useMedia(mediaQueries.mobile);
    const isPcSite = useMedia(mediaQueries.pc);
    const siteType = useMemo(() => ({ isMobileSite, isPcSite }), [
            isMobileSite,
            isPcSite
    ]);

    // 初回のレンダリングのみ
    useEffect(() => {
        if (typeof process.env.REACT_APP_APIKEY === "undefined"){
            fetch('/__/firebase/init.json').then(async response => {
                const firebaseConfig = await response.json();
                initializeApp(firebaseConfig);
                setInitFlag(true);
            });
        } else {
            const firebaseConfig = {
                apiKey: process.env.REACT_APP_APIKEY,
                authDomain: process.env.REACT_APP_AUTHDOMAIN,
                projectId:process.env.REACT_APP_PROJECTID,
                storageBucket: process.env.REACT_APP_STORAGEBUCKET,
                messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
                appId: process.env.REACT_APP_APPID
            };
            initializeApp(firebaseConfig);
            setInitFlag(true);
        }
        // modeの設定
        const queryParams = new URLSearchParams(window.location.search)
        const queryMode = queryParams.get('mode') || ''
        if (queryMode) {
            setMode(queryMode)
        }
    }, [])
    
    // modeによる分岐
    if ( mode === 'resetPassword' ) {
        // resetPasswordの場合
        return (
            <SiteType.Provider value={siteType}>
                {initFlag ? (<PasswordChange />) : (<></>)}
            </SiteType.Provider>
        )
    } else if ( mode === 'verifyEmail' ) {
        // verifyEmailの場合
        return (
            <SiteType.Provider value={siteType}>
                {initFlag ? (<VerifyEmail />) : (<></>)}
            </SiteType.Provider>
        )
    } else if ( mode === 'verified' ) {
        // verifiedの場合
        return (
            <SiteType.Provider value={siteType}>
                {initFlag ? (<Verified />) : (<></>)}
            </SiteType.Provider>
        )
    }  else {
        return (
            <div id={isPcSite ? "formArea" : "formArea-Mobile"}>
                <h1 className="title" id={isPcSite ? "PC" : "Mobile"}>Ashirase</h1>
                <div id="succeeded">
                    <p>あしらせアプリを開いてログインしてください。</p>
                </div>
            </div>
        )
    }
}

export default App;
