import React, {useState, useEffect, createContext, useContext} from 'react';
import { verifyPasswordResetCode, confirmPasswordReset, getAuth } from 'firebase/auth'
import './PasswordChange.css';
import Entry from './Entry'
import Result from './Result'
import '@fortawesome/fontawesome-free/js/fontawesome';
import '@fortawesome/fontawesome-free/js/solid';
import '@fortawesome/fontawesome-free/js/regular';
import { SiteType } from './App';

export type ContextType = {
    errorMessage: string[];
    setErrorMessage: React.Dispatch<React.SetStateAction<string[]>>;
    password1: string;
    setPassword1: React.Dispatch<React.SetStateAction<string>>;
    password2: string;
    setPassword2: React.Dispatch<React.SetStateAction<string>>;
    resultFlag: boolean;
    setResultFlag: React.Dispatch<React.SetStateAction<boolean>>;
    actionCode: string;
    setActionCode: React.Dispatch<React.SetStateAction<string>>;
    processdFlag: boolean;
    setProcessdFlag: React.Dispatch<React.SetStateAction<boolean>>;
    initCheck: boolean;
    setInitCheck: React.Dispatch<React.SetStateAction<boolean>>;
    handleSubmit: (event: React.SyntheticEvent) => void;
}
export const State = createContext<Partial<ContextType>>({});

const PasswordChange = (prop: any) => {
    const { isPcSite } = useContext(SiteType);
    
    const [actionCode, setActionCode] = useState<string>('')  
    const [password1, setPassword1] = useState<string>('')
    const [password2, setPassword2] = useState<string>('')
    const [errorMessage, setErrorMessage] = useState([''])
    const [processedFlag, setProcessedFlag] = useState<boolean>(false)
    const [resultFlag, setResultFlag] = useState<boolean>(false)
    const [initCheck, setInitCheck] = useState<boolean>(false)

    // 入力チェック用のパラメータの読み込み
    const errorPasswordNotMatch: string = "入力されたパスワードが一致していません";
    const errorEmptyMessage: string = "パスワードを入力してください";
    const errorMinLength: number = 8
    const errorMinLengthMessage: string = "パスワードは8文字以上で入力してください"
    const errorMaxLength: number = 255
    const errorMaxLengthMessage: string = "パスワードは255文字以下で入力してください"
    const errorAlphabetAndNumberAndSymbol: string = "^[A-Za-z0-9!#$%&@]*$"
    const errorCharacterTypeMessage: string = "パスワードは半角英数記号(!#$%&@)で入力してください"
    const errorWithoutAlphabetNumberMessasge: string = "パスワードは文字と数字を必ず含めてください"

    // 初回のレンダリングのみ
    useEffect(() => {
        // actionCodeチェック
        const queryParams = new URLSearchParams(window.location.search)
        const oobCode = queryParams.get('oobCode') || ''
        if (oobCode) {
            setActionCode(oobCode)
            verifyPasswordResetCode(getAuth(), oobCode)
            .catch((error) => {
                console.log(error)
                setProcessedFlag(true);
                setResultFlag(false);
            })
            .finally(() => {
                setErrorMessage([])
                setInitCheck(true);
            })
        }
    }, [])

    // サブミットイベントをキャッチする
    const handleSubmit = (event: React.SyntheticEvent) => {
        event.preventDefault()

        // エラーメッセージの初期化
        let errorStrings: string[] = []
        let errorFlag: boolean = false

        // パスワード1とパスワード2が一致していなかった場合はエラー
        if (errorPasswordNotMatch && password1 !== password2) {
            errorStrings = [...errorStrings, errorPasswordNotMatch]
            errorFlag = true
        }

        // パスワードが入力されていない場合はチェックエラー
        if (errorEmptyMessage && !password1) {
            errorStrings = [...errorStrings, errorEmptyMessage]
            errorFlag = true
        }
        
        // 入力文字数が8文字未満の場合はチェックエラー
        if (errorMinLength && errorMinLengthMessage && password1.length < errorMinLength) {
            errorStrings = [...errorStrings, errorMinLengthMessage]
            errorFlag = true
        }

        // 入力文字数が256文字未満の場合はチェックエラー
        if (errorMaxLength && errorMaxLengthMessage && password1.length > errorMaxLength) {
            errorStrings = [...errorStrings, errorMaxLengthMessage]
            errorFlag = true
        }

        // 利用NGの文字が含まれていた場合はチェックエラー
        const regexIsOnlyAlphabetAndNumberAndSymbol = errorAlphabetAndNumberAndSymbol ? new RegExp(errorAlphabetAndNumberAndSymbol, "g") : undefined;
        if (regexIsOnlyAlphabetAndNumberAndSymbol && errorCharacterTypeMessage && !password1.match(regexIsOnlyAlphabetAndNumberAndSymbol)) {
            errorStrings = [...errorStrings, errorCharacterTypeMessage]
            errorFlag = true
        }

        // 文字と数字の両方を含まない場合はチェックエラー
        const regexIncludeNumber = /[0-9]+/;
        const regexIncludeAlphabet = /[A-Za-z]+/;
        if (errorWithoutAlphabetNumberMessasge && !(password1.match(regexIncludeNumber) && password1.match(regexIncludeAlphabet)) ) {
            errorStrings = [...errorStrings, errorWithoutAlphabetNumberMessasge]
            errorFlag = true
        }
        
        // エラーが発生した場合
        if (errorFlag) {
            // エラーメッセージの設定
            setErrorMessage(errorStrings)
        } else {
            // パスワードの変更
            confirmPasswordReset(getAuth(), actionCode, password2)
            .then(() => {
                setResultFlag(true)
            })
            .catch((error) => {
                console.log(error)
                setResultFlag(false)
            })
            .finally(() => {
                setProcessedFlag(true);
            })
        }
    }

    const state = {
      errorMessage,
      setErrorMessage,
      password1,
      setPassword1,
      password2,
      setPassword2,
      resultFlag,
      setResultFlag,
      actionCode,
      setActionCode,
      processedFlag,
      setProcessedFlag,
      initCheck,
      setInitCheck,
      handleSubmit
    };
  
    return (
        <>
            {initCheck ? (
                <State.Provider value={state}>
                    <div id={isPcSite ? "formArea" : "formArea-Mobile"}>
                        <h1 className="title" id={isPcSite ? "PC" : "Mobile"}>パスワード変更</h1>
                        {!processedFlag ? <Entry /> : <Result />}
                    </div>
                </State.Provider>
            ) : (
                <></>
            )}
        </>
    )
}

export default PasswordChange;
