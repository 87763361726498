import {useContext} from 'react';
import { SiteType } from './App';

const Verified = () => {
    const { isPcSite } = useContext(SiteType);
    return (
        <div id={isPcSite ? "formArea" : "formArea-Mobile"}>
            <h1 className="title" id={isPcSite ? "PC" : "Mobile"}>認証完了</h1>
            <div id="succeeded">
                <p>新しいアカウントでログインできるようになりました</p>
                <p>あしらせアプリを開いて認証済みのメールアドレスでログインしてください。</p>
            </div>
        </div>
    )
}

export default Verified;
