import ErrorDisplay from './ErrorDisplay';
import { useState, useContext } from 'react'
import { SiteType } from './App';
import { State } from './PasswordChange'

const Entry = () => {
    const { isPcSite } = useContext(SiteType);
    const { password1, setPassword1, password2, setPassword2, handleSubmit } = useContext(State);

    const [isRevealPassword, setIsRevealPassword] = useState(false);
    const [isRevealPassword2, setIsRevealPassword2] = useState(false);

    // パスワード入力フォーム1の表示/非表示切り替え
    const togglePassword = () => {
        setIsRevealPassword((prevState) => !prevState);
    }

    // パスワード入力フォーム2の表示/非表示切り替え
    const togglePassword2 = () => {
        setIsRevealPassword2((prevState) => !prevState);
    }

    return (
        <>
            <form onSubmit={handleSubmit} name="password_form">
                <p>新しいパスワードを入力してください。</p>
                <ErrorDisplay />
                <div id={isPcSite ? "form_container_PC" : "form_container_Mobile"}>
                    <div id={isPcSite ? "password_form_child_PC" : ""}>
                        <label htmlFor="password1" id={isPcSite ? "PC" : "Mobile"}>パスワード　　　　　</label>
                        <div id="form">
                            <input
                                id="password1"
                                type={isRevealPassword ? 'text' : 'password'}
                                value={password1}
                                name="textarea"
                                className="pw1"
                                onChange={(event) => setPassword1 && setPassword1(event.target.value)}
                            />
                            <span
                                onClick={togglePassword}
                                role="presentation"
                                className="passwordReveal"
                                hidden={!isRevealPassword}
                            >
                                <i className="far fa-eye"/>
                            </span>
                            <span
                                onClick={togglePassword}
                                role="presentation"
                                className="passwordReveal"
                                hidden={isRevealPassword}
                            >
                                <i className="far fa-eye-slash" />
                            </span>
                        </div>
                    </div>
                    <p></p>
                    <div id={isPcSite ? "password_form_child_PC" : ""}>
                        <label htmlFor="password2" id={isPcSite ? "PC" : "Mobile"}>パスワード（再入力）</label>
                        <div id="form">
                            <input
                                id="password2"
                                type={isRevealPassword2 ? 'text' : 'password'}
                                value={password2}
                                name="textarea"
                                className="pw2"
                                onChange={(event) => setPassword2 && setPassword2(event.target.value)}
                            />
                            <span
                                onClick={togglePassword2}
                                role="presentation"
                                className="passwordReveal"
                                hidden={!isRevealPassword2}
                            >
                                <i className="far fa-eye"/>
                            </span>
                            <span
                                onClick={togglePassword2}
                                role="presentation"
                                className="passwordReveal"
                                hidden={isRevealPassword2}
                            >
                                <i className="far fa-eye-slash" />
                            </span>
                        </div>
                    </div>
                </div>
                <input type="submit" name="button" value="登録" />
            </form>
        </>        
    )
}

export default Entry;
