import {applyActionCode, getAuth } from 'firebase/auth'
import {useState, useEffect, useContext} from 'react';
import { SiteType } from './App';


const VerifyEmail = () => {
    const { isPcSite } = useContext(SiteType);
    const [initCheck, setInitCheck] = useState<boolean>(false);
    const [resultFlag, setResultFlag] = useState<boolean>(false);

    // 初回のレンダリングのみ
    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search)
        const oobCode = queryParams.get('oobCode') || ''
        if (oobCode) {
            // メールアドレスの検証
            applyActionCode(getAuth(), oobCode)
            .then(() => {
                setResultFlag(true)
            })
            .catch((error) => {
                console.log(error)
                setResultFlag(false)
            })
            .finally(() => {
                setInitCheck(true);
            })
        }
    }, [])

    const uri = new URL(window.location.href);
    const regex_dev = /dev/;
    const regex_local = /localhost/;
    var href = ""
    if (regex_dev.test(uri.hostname)) {
        href = "https://app-open-dev.ashirase.com/?link=https://ashirase-dev.web.app/?mode%3Dverified&isi=1631484935&ibi=com.ashirase.ashirase.dev&efr=1";
    } else if (regex_local.test(uri.hostname)) {
        href = "http://localhost:3002/?mode=verified";
    } else {
        href = "https://app-open.ashirase.com/?link=https://ashirase-c6f10.web.app/?mode%3Dverified&isi=1631484935&ibi=com.ashirase.ashirase&efr=1";
    }

    if (initCheck) {
        return (
            <>
                <div id={isPcSite ? "formArea" : "formArea-Mobile"}>
                    <h1 className="title" id={isPcSite ? "PC" : "Mobile"}>Email認証</h1>
                    {resultFlag ? (
                        <div id="succeeded">
                            <p>新しいアカウントでログインできるようになりました</p>
                            <p>下記のリンクからアプリに戻ってください</p>
                            <a href={href}>アプリに戻る</a>
                        </div>
                    ) : (
                        <div id="failed">
                            <p>メールアドレスを再度確認してください</p>
                            <p>メールアドレスの確認のリクエストの期限が切れたか、リンクがすでに使用されています</p>
                        </div>
                    )}
                </div>
            </>
        )
    } else {
        return <></>
    }
}

export default VerifyEmail;
