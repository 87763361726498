import './Result.css';
import { useContext } from 'react';
import { State } from './PasswordChange'

const Result = () => {
    const { resultFlag } = useContext(State);
    return (
        <>
            {resultFlag ? (
                <div id="succeeded">
                    <p>パスワードを変更しました。</p>
                    <p>あしらせのアプリから新しいパスワードでログインしてください。</p>
                </div>
            ) : (
                <div id="failed">
                    <p>パスワードの再設定をもう一度お試しください</p>
                    <p>パスワードの再設定のリクエストの期限が切れたか、リンクがすでに使用されています</p>
                </div>
            )}
        </>
    )
}

export default Result
